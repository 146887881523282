/* Home Style */
.home-container {
    /* max-width: 90% !important; */
    background-color: #ffffff;
    border-color: #ffffff;
    border-width: 5px;
    border-style: solid;
}

.home-heading {
    font-size: 4rem;
    margin-bottom: 3rem;
}

.home-text {
    font-size: 1.5rem;
    line-height: 1.6;
    margin: 0 auto;
    max-width: 4000px;
    padding: 0 4rem;
}

.home-button {
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
    padding-md: 1rem 2rem;
}

.garden-icon {
    font-size: 8rem;
    margin-bottom: 1rem;
}

.garden-heading {
    font-size: 3rem;
}

.garden-text {
    font-size: 1.6rem;
    line-height: 1.5;
    margin-bottom: 0.5rem;
}

.volunteer-heading {
    font-size: 3rem;
}

.volunteer-text {
    font-size: 1.6rem;
    line-height: 1.5;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
}

.volunteer-date {
    font-size: 2.2rem;
    margin: 1rem 0;
}

.artist-image {
    max-width: 100%;
    border-radius: 0.5rem;
}

.artist-text {
    font-size: 1.6rem;
    line-height: 1.5;
    margin-bottom: 1rem;
}



/* About Style */
.about-container {
    /* max-width: 90% !important; */
}

.about-heading {
    font-size: 3rem;
    margin-bottom: 1.5rem;
}

.about-subheading {
    font-size: 2.25rem;
    margin-bottom: 1.5rem;
}

.about-text {
    font-size: 1.75rem;
    margin-bottom: 1.5rem;
}

.about-link {
    font-size: 2rem;
}

.about-img {
    width: 100%;
    border-radius: 0.5rem;
}

.about-section-heading {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
}

.about-section-text {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
}

.about-icon {
    font-size: 9rem;
    color: #28a745; /* Bootstrap's 'success' color */
    margin-bottom: 1.5rem;
}

.volunteer-heading {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
}

.volunteer-list {
    font-size: 1.25rem;
    margin-bottom: 1.25rem;
}

.volunteer-btn {
    font-size: 1.2rem;
    padding: 0.75rem 1.5rem;
}

.farm-stand-heading {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
}

.farm-stand-subheading {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
}

.farm-stand-link {
    font-size: 1.6rem;
    margin-bottom: 1.5rem;
}

.farm-stand-btn {
    font-size: 1.4rem;
    padding: 0.75rem 1.5rem;
}



/* Events Style */
#events {
    background-color: #ffffff;
    border-color: #ffffff;
    border-width: 5px;
    border-style: solid;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    max-width: 95%;
    padding: 20px;
    border-radius: 0.5rem;
}

h2.upcoming-events-title {
    font-size: 2.5rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin: 0 auto;
    max-width: 85%;
    color: #2a7d2e;
    text-decoration: none;
}

p.events-description {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    color: #555;
    margin: 0 auto;
    /* max-width: 90%; */
    line-height: 1.6;
}

iframe.events-calendar {
    border: 0;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

button.event-button {
    font-size: 1.2rem;
    padding: 0.75rem 1.5rem;
    width: 100%;
    border-radius: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}



/* Composting.css */
.composting-container {
    /* max-width: 90% !important; Ensure the max-width is applied */
    background-color: #ffffff;
    border-color: #ffffff;
    border-width: 5px;
    border-style: solid;
}

.composting-heading {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.composting-subheading {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.composting-text {
    font-size: 1.6rem;
    line-height: 1.5;
    margin-bottom: 0.5rem;
}

.composting-img {
    border-radius: 5px;
    border: 2px solid #ffffff;
    max-width: 100%;
}

.composting-list {
    font-size: 1.8rem;
    line-height: 1.6;
    list-style-type: disc;
    padding-left: 1.5rem;
}

.composting-location {
    font-size: 1.8rem;
    line-height: 1.6;
}

.composting-section {
    padding: 2rem;
}

.shadow {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add the shadow effect */
}

.text-center {
    text-align: center;
}


/* Composting.css */
.composting-container {
    /* max-width: 90% !important; */
    background-color: #ffffff;
    border-color: #ffffff;
    border-width: 5px;
    border-style: solid;
}

.composting-text-title {
    font-size: 1.6rem;
    margin-bottom: 0.5rem;
}

.composting-heading {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.composting-subheading {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.composting-text {
    font-size: 1.8rem;
    line-height: 1.6;
    margin-bottom: 1rem;
}

.composting-list {
    font-size: 1.8rem;
    line-height: 1.6;
    list-style-type: disc;
    padding-left: 1.5rem;
    margin-bottom: 0;
}

.composting-location {
    font-size: 1.8rem;
    line-height: 1.6;
}

.composting-image {
    border-radius: 5px;
    border: 2px solid #ffffff;
    max-width: 100%;
}

.composting-section {
    padding: 2rem;
}



/* Greenhouse Style */
.greenhouse-container {
    /* max-width: 90% !important; */
    background-color: #ffffff;
    border-color: #ffffff;
    border-width: 5px;
    border-style: solid;
    padding: 2rem;
}

.greenhouse-heading {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.greenhouse-text {
    font-size: 1.8rem;
    line-height: 1.6;
}

.greenhouse-list {
    font-size: 1.8rem;
    line-height: 1.6;
    list-style-type: disc;
    padding-left: 1.5rem;
    margin-bottom: 1rem;
}



/* Contact Style */
.contact-container {
    /* max-width: 90% !important; */
    background-color: #ffffff;
    border-color: #ffffff;
    border-width: 5px;
    border-style: solid;
    padding: 2rem;
}

.contact-icon {
    color: #000000;
}

.contact-heading {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
}

.contact-link, .contact-address {
    font-size: 1.6rem;
    margin-bottom: 0;
}





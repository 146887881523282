/* Google Font */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap');

:root {
    --color-black: #2f2f2f;
    --color-green: #2a7d2e;
    --color-white: #fff;
    --color-grey: #ffffff80;
    --color-heading: #0b3d2c;
}

body,
html,
p,
td,
th {
    color: var(--color-black);
}

body {
    position: relative;
    font-family: 'Montserrat', sans-serif;
}


/*
 * Globals
 */

 a, a:link, a:visited {
  text-decoration: none;
  color: var(--color-green);
}

a:hover,
a:hover {
  color: var(--color-green);
  text-decoration: underline;
}

/*
* Base structure
*/
body {
  text-shadow: 0 .05rem .1rem rgba(0, 0, 0, .5);
  /* box-shadow: inset 0 0 5rem rgba(0, 0, 0, .5); */
  background-color: var(--color-green);
}

/* Footer */
footer a,
footer a:link,
footer a:visited,
footer a:active {
  color: var(--color-gray);
}

footer a:hover {
  color: var(--color-white);
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
      font-size: 3.5rem;
  }
}

@media (min-width: 800px) {
  body {
      height: 100vh !important;
  }
}
/*
* Header
*/
.nav-masthead .nav-link {
  padding: .25rem 0;
  font-weight: 700;
  color: rgba(255, 255, 255, .5);
  background-color: transparent;
  border-bottom: .25rem solid transparent;
}

.nav-masthead .nav-link:hover,
.nav-masthead .nav-link:focus  {
  border-bottom-color: rgba(255, 255, 255, 1);
  text-decoration: none;
  color: rgba(255, 255, 255, 1);
}
.nav-masthead .nav-link.badge:hover,
.nav-masthead .nav-link.badge:focus {
  border-bottom-color: transparent;
}

.nav-masthead .nav-link+.nav-link {
  margin-left: 1rem;
}

.nav-masthead .active {
  color: var(--color-white);
  border-bottom-color: var(--color-white);
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

/* Main */
h3 {
  color: var(--color-heading);
  font-weight: 300;
}

.button {
  background-color: yellow !important;
}

.center{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
